body, #root{
    padding:0!important;
    margin: 0!important;
}

#loginPage{
    height: 100vh;
    width: 100%;
    background: url(../../img/backgroundLogin.png);
}

.loginPanel{
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 25px;
    width: 400px;
    height: 300px;
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}