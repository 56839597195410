/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: grey #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 20px;
    border: 2px solid grey;
  }

  a{
    text-decoration: none;
    color:black;
    font-weight: bold;
  }
  a.active{
    color:rgb(36, 113, 255);
    background-color: rgba(0,0,0, 0.03);
  }

  a span{
    font-size:14px;
  }

  a.active svg{
    color:rgb(36, 113, 255);
  }

  *, body{
    overflow-x: hidden;
  }

  .MuiDivider-wrapper{
    margin:0;
    padding:0;
    width:150px;
  }